<template>
<icon-base :width="size + 'px'" :fill="color">
    <path class="st0" d="M240.9,0h-85v80.6L125,59.8L94.1,80.6V0h-85C4.1,0,0,4.1,0,9.1v219.8C0,240.5,9.5,250,21.1,250h62h145.8  c11.6,0,21.1-9.4,21.1-21.1V56.2V9.1C250,4.1,245.9,0,240.9,0z M224.8,218.8h-96.8v-24.4h96.8V218.8z M224.8,178.9H79.7v-24.4h145.1  V178.9z"/>
</icon-base>
</template>

<script>
export default {
name: 'iconOrder',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
    type: Number,
    default: 20
    },
    color: {
    type: String,
    default: 'var(--main-navy)'
    },
    enableBackground: {
    type: Boolean
    }
}
}
</script>

